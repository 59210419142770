<template>
  <banner :list="list"></banner>

  <menu-tab
      :menu="menu"
      :swiper="true"
      :initIndex="0"
      :slidesPerView="6"
      @change="selectMenu"
  ></menu-tab>

    <list :url="standardUrl" :params="{ firstClass: 0}" :filterFunc="standardMap" :show="currentMenuItem == '全部'">
      <template v-slot:default="data">
         <product-card :list="data" name="standarddetail"></product-card>
      </template>
    </list>
    <list :url="standardUrl" :params="{ firstClass: 1}" :filterFunc="standardMap" :show="currentMenuItem == '食品'">
      <template v-slot:default="data">
         <product-card :list="data" name="standarddetail"></product-card>
      </template>
    </list>
    <list :url="standardUrl" :params="{ firstClass: 2}" :filterFunc="standardMap" :show="currentMenuItem == '环保'">
      <template v-slot:default="data">
         <product-card :list="data" name="standarddetail"></product-card>
      </template>
    </list>
    <list :url="standardUrl" :params="{ firstClass: 3}" :filterFunc="standardMap" :show="currentMenuItem == '医药'">
      <template v-slot:default="data">
         <product-card :list="data" name="standarddetail"></product-card>
      </template>
    </list>
    <list :url="standardUrl" :params="{ firstClass: 68}" :filterFunc="standardMap" :show="currentMenuItem == '能源'">
      <template v-slot:default="data">
         <product-card :list="data" name="standarddetail"></product-card>
      </template>
    </list>
    <list :url="standardUrl" :params="{ firstClass: 69}" :filterFunc="standardMap" :show="currentMenuItem == '化工'">
      <template v-slot:default="data">
        <product-card :list="data" name="standarddetail"></product-card>
      </template>
    </list>

</template>

<script lang="ts">
import { defineComponent, onMounted, ref, onUnmounted } from "vue";
import banner from "@/components/common/Swiper.vue";
import list from "@/components/common/list.vue";
import productCard from "@/components/common/productCard.vue";
//  utils
import api from "@/api/axios";
import Swiper from "swiper";
import $ from "jquery";
import { throttle } from "@/utils/utils";

//ts
import { standardMap } from "@/hooks/useProduct"
import MenuTab from "@/components/common/MenuTab.vue";
export default defineComponent({
  name: "product",
  components: {
    banner,
    list,
    productCard,
    MenuTab
  },
  setup() {

    //  切换列表菜单
    type Menu = "全部" | "食品" | "环保" | "医药" | "能源" | "化工";
    const menu: Menu[] = ["全部", "食品", "环保", "医药", "能源", "化工"];
    const currentMenuItem = ref(menu[0]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }

    const list = ref(null);
    const standardUrl = "/M/Server/GetProductLastbz";

    api.get("/M").then((res): void => {
      list.value = res.data.obj.listimg;
    });

    return {
      list,
      standardUrl,
      standardMap,
      menu,
      currentMenuItem,
      selectMenu,
    };
  }
});
</script>
<style lang="scss" scoped>
</style>
