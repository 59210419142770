
import { defineComponent, onMounted, ref, onUnmounted } from "vue";
import banner from "@/components/common/Swiper.vue";
import list from "@/components/common/list.vue";
import productCard from "@/components/common/productCard.vue";
//  utils
import api from "@/api/axios";
import Swiper from "swiper";
import $ from "jquery";
import { throttle } from "@/utils/utils";

//ts
import { standardMap } from "@/hooks/useProduct"
import MenuTab from "@/components/common/MenuTab.vue";
export default defineComponent({
  name: "product",
  components: {
    banner,
    list,
    productCard,
    MenuTab
  },
  setup() {

    //  切换列表菜单
    type Menu = "全部" | "食品" | "环保" | "医药" | "能源" | "化工";
    const menu: Menu[] = ["全部", "食品", "环保", "医药", "能源", "化工"];
    const currentMenuItem = ref(menu[0]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }

    const list = ref(null);
    const standardUrl = "/M/Server/GetProductLastbz";

    api.get("/M").then((res): void => {
      list.value = res.data.obj.listimg;
    });

    return {
      list,
      standardUrl,
      standardMap,
      menu,
      currentMenuItem,
      selectMenu,
    };
  }
});
